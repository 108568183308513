import React, { useEffect, useState } from 'react'
import topareagif from '../assets/imgs/Background1.gif'
import { motion, useAnimation } from 'framer-motion';
import strip from '../assets/imgs/connectors/strip.svg';
import arrow from '../assets/imgs/arrow.svg'

export const Toparea = ({ isSmallScreen }) => {

    const slidingAnimation = {
        hidden: { x: '-6000%' },  // Start outside the screen on the left
        visible: {
            x: '100%',           // End outside the screen on the right
            transition: {
                duration: 1000,     // Duration of one cycle of the animation
                ease: "linear",  // Linear easing for a smooth continuous motion
                repeat: Infinity // Repeat the animation infinitely
            }
        }
    };




    return (
        <div className='toparea wht'>
            <img src={topareagif} alt="topareagif" className='topareagif' />
            {isSmallScreen ?
                <div className="txt-cnt">
                    <div className="spray red">$KEVIN#</div>
                    <div className="ss">$<span className="red mobred">KEV</span> the <span className="yellow">solilionaire</span> token on <span className="red">solana</span> </div>
                    <a href="https://raydium.io/swap/?outputCurrency=31wUXiQXKnTETRKDwDGxS8KKJepVcEDugcGBRp8pkJj5">
                        <div className="red-btn">
                            buy $kev
                        </div>
                    </a>

                </div>
                : <div className="txt-cnt">
                    <div className="spray red">$KEV=IN###</div>
                    <div className="ss">$<span className="red mobred">KEV</span> the <span className="yellow">solilionaire</span> token on <span className="red">solana</span> </div>
                    <a href="https://raydium.io/swap/?outputCurrency=31wUXiQXKnTETRKDwDGxS8KKJepVcEDugcGBRp8pkJj5">
                        <div className="red-btn">
                            buy $kev
                        </div>
                    </a>
                </div>
            }

            <div style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={slidingAnimation}
                    style={{ width: '100%', display: 'flex', height: "60px" }} // 200% to contain two copies of the strip side by side
                >
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />

                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />

                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />

                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />

                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />


                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />

                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />

                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />

                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />

                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                    <img src={strip} alt="Checkered Strip" />
                </motion.div>
            </div>


        </div>
    )
}
