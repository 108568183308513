import { useState, useEffect } from "react";
import { Navbar } from "./components/Navbar";
import { Toparea } from "./components/Toparea";
import { Loadscreen } from "./components/Loadscreen";
import { Aboutarea } from "./components/Aboutarea";
import { Footer } from "./components/Footer";
// import { Tokenomicsarea } from "./components/Tokenomicsarea";
// import { Loadscreen } from "./components/Loadscreen";

function App() {

  // const [isStoryOn, setIsStoryOn] = useState(false)

  const [loading, setLoading] = useState(false)


  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect runs only on mount and unmount



  return (
    <div className="App">

      <Loadscreen loading={loading} setLoading={setLoading} isSmallScreen={isSmallScreen } />

      <Navbar setLoading={setLoading} loading={loading} />
      <Toparea isSmallScreen={isSmallScreen}  />
      <Aboutarea setLoading={setLoading} />
      <Footer />


    </div>
  );
}

export default App;
