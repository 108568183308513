import React from 'react'
import { FaXTwitter } from 'react-icons/fa6';
import { FaTelegram } from 'react-icons/fa6';
import dexscreener from '../assets/imgs/logos/dexscreener.svg';
import kevfoot from '../assets/imgs/KevOtis1_Transp.png'
import { motion } from 'framer-motion'

import { IconContext } from 'react-icons';

export const Footer = () => {
    return (
        <div className='footer tint'>

            <div className="leftlogos">
                <a href="https://twitter.com/KevSolVip"><FaXTwitter className='ic ic1' /></a>
                <a href="https://t.me/kevinsol_portal69"><FaTelegram className='ic ic2' /></a>
                <a href="https://dexscreener.com/solana/31wUXiQXKnTETRKDwDGxS8KKJepVcEDugcGBRp8pkJj5"><img src={dexscreener} alt='dexscreener' className='ic ic3' /></a>


            </div>

            <img src={kevfoot} alt={kevfoot} className='kevfoot' />

            <div className="right-area">
                <div className="ss"><span className="red">$KEV</span> the <span className="yellow">solilionaire</span> token on <span className="red">solana</span> </div>
                <div className="warn">
                    <div className="arc">This token is a meme, has no financial monetary value and is purely for entertainment purposes</div>
                </div>
                <a href="https://raydium.io/swap/?outputCurrency=31wUXiQXKnTETRKDwDGxS8KKJepVcEDugcGBRp8pkJj5">
                    <div className="red-btn">$KEV $KEV $KEV</div>
                </a>
            </div>
        </div>
    )
}
