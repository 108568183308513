import React, { useState, useEffect } from 'react'
import arrow from '../assets/imgs/arrow.svg'
import arrow2 from '../assets/imgs/arrow2.svg'
import MoneyFalling2 from '../assets/imgs/MoneyFalling2.gif'
import nacvon from '../assets/imgs/connectors/nav-wide-connector.png'
import { RiMenu4Fill } from "react-icons/ri";
import { GiSplitCross } from "react-icons/gi";

import { IconContext } from 'react-icons';
import { motion, AnimatePresence } from 'framer-motion'
import { FaXTwitter } from 'react-icons/fa6';
import { FaTelegram } from 'react-icons/fa6';
import dexscreener from '../assets/imgs/logos/dexscreener.svg';

export const Navbar = ({ loading, setLoading }) => {

    const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        updateIsMobile();


        window.addEventListener('resize', updateIsMobile);

        return () => {
            window.removeEventListener('resize', updateIsMobile);
        };


    }, []);


    const variants = {
        animate: {
            x: ["-5%", "5%"], // Adjust the values to control the movement range
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 0.8, // Duration of one left-right cycle
                }
            }
        }
    };



    const [navbarPopup, setNavbarpopup] = useState(false);


    return (
        <div className='navbar tint'>

            <div className="left">
                {loading ?
                    <img src={MoneyFalling2} alt="MoneyFalling2" className='MoneyFalling2' onClick={() => setLoading(false)} />
                    : <img src={MoneyFalling2} alt="MoneyFalling2" className='MoneyFalling2' onClick={() => setLoading(true)} />}

                {loading ?
                    <motion.img variants={variants}
                        animate="animate"
                        src={arrow2} alt="arrow" className='arrow' onClick={() => setLoading(false)} />
                    : <motion.img variants={variants}
                        animate="animate"
                        src={arrow} alt="arrow" className='arrow' />}



                {loading ? <div className="hyp uptx txt">
                    Click <span className="red">here</span> to close kEV’S sTORY
                </div> : <div className="hyp uptx txt">
                    Click <span className="red">here</span> to see kEV’S sTORY
                </div>}

            </div>

            <div className="right">
                <div className="txt arc">
                    <span className="red">CA :</span> 31wUXiQXKnTETRKDwDGxS8KKJepVcEDugcGBRp8pkJj5
                </div>
                <div className="icons">
                    <a href="https://twitter.com/KevSolVip"><FaXTwitter className='ic ic1' /></a>
                    <a href="https://t.me/kevinsol_portal69"><FaTelegram className='ic ic2' /></a>
                    <a href="https://dexscreener.com/solana/31wUXiQXKnTETRKDwDGxS8KKJepVcEDugcGBRp8pkJj5"><img src={dexscreener} alt='dexscreener' className='ic ic3' /></a>

                </div>

            </div>

            <img src={nacvon} alt="navcon" className='navcon' />

            {/* {
                !loading && <img src={nacvon} alt="navcon" className='navcon' />


            } */}
        </div>
    )
}
