import React from 'react'
import doodlesvg from '../assets/imgs/connectors/doodlesvg.svg'
import Background2 from '../assets/imgs/Background2.gif'
import labelpng from '../assets/imgs/labelpng.png'
import kevgirl from '../assets/imgs/Kev_Girl1.png'
import kevjump from '../assets/imgs/Kev_Jump.gif'
import ghettogold from '../assets/imgs/Ghetto-toGold1.gif'
import { motion } from 'framer-motion'

export const Aboutarea = ({ setLoading }) => {


    const variants = {
        animate: {
            scale: [1, 0.9, 1],
            transition: {
                duration: 0.5,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut"
            }
        }
    };


    return (
        <div className='aboutarea blk'>

            <div className="area1">

                <div className="title spray">KEVS JOURN=EY$#</div>

                <div className="dual">

                    <div className="img-area">

                        <img src={Background2} alt="Background2" className='bg2' />
                        <img src={doodlesvg} alt="doodlesvg" className='doodlesvg' />

                    </div>
                    <div className="text-area arc">
                        <p className="txt">Join the community and watch Kev's story unfold. Degen from zero to hero. Conquer the highs of green dildos and lows of nuclear jeetery to become the ultimate SOLilionaire alongside the global meme sensation Kev. </p>
                        <motion.img variants={variants}
                            animate="animate"
                            src={labelpng} alt="labelpng" className='labelpng' onClick={() => setLoading(true)} />
                        <p className="txt">Kevin once took an IQ test and the result it gave him was the single digit 1. Kev interpreted this as his global IQ rank. To Kevin he was always Number 1.</p>
                        <p className="txt sptxt">if KEV can do it, so can you!</p>
                    </div>

                </div>


                <div className="about-tkn">

                    <div className="box box1">
                        <div className="titl hyp">TOTAL SUPPLY</div>
                        <div className="arc">69,000,000</div>
                    </div>

                    <div className="box box2">
                        <div className="titl hyp">TICKER</div>
                        <div className="arc">$KEV</div>
                    </div>

                    <div className="box box3">
                        <div className="titl hyp">CONTRACT ADDRESS</div>
                        <div className="arc arc3">31wUXiQXKnTETRKDwDGxS8KKJepVcEDugcGBRp8pkJj5
                        </div>
                    </div>

                    <div className="tri-box">

                        <div className="box box4">
                            <div className="titl hyp">LP POOL</div>
                            <div className="arc arc3">BURNT
                            </div>
                        </div>



                        <div className="box box5">
                            <div className="titl hyp">TAXES</div>
                            <div className="arc arc3">0/0
                            </div>


                        </div>

                        <div className="box box6">
                            <div className="titl hyp">POWERED BY</div>
                            <div className="arc arc3">COMMUNITY
                            </div>
                        </div>
                    </div>



                </div>

                <img src={kevjump} alt="kevjump" className='kevjump' />

                <motion.img variants={variants}
                    animate="animate" src={kevgirl} alt="kevgirl" className='kevgirl' />
            </div>

            <div className="area2">
                <img src={ghettogold} alt="ghettogold" className='ghettogold' />
                <a href="https://raydium.io/swap/?outputCurrency=31wUXiQXKnTETRKDwDGxS8KKJepVcEDugcGBRp8pkJj5"><div className="red-btn">Buy $KEV On Sol</div></a>
            </div>
        </div>
    )
}
