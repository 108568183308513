import React from 'react'
// import skullimg from '../assets/imgs/fl-skull-1.png'
import { motion, AnimatePresence } from 'framer-motion';
import { Tweet } from 'react-tweet';
import vid2 from '../assets/vids/vid2.mp4'
import vid3 from '../assets/vids/vid3.mp4'
import vid4 from '../assets/vids/vid4.mp4'
import vid5 from '../assets/vids/vid5.mp4'
import vid6 from '../assets/vids/vid6.mp4'
import vid7 from '../assets/vids/vid7.mp4'
import vid8 from '../assets/vids/vid8.mp4'
import vid9 from '../assets/vids/vid9.mp4'
import vid10 from '../assets/vids/vid10.mp4'
import vid11 from '../assets/vids/vid11.mp4'
import vid12 from '../assets/vids/vid12.mp4'

import arrow from '../assets/imgs/arrow2.svg'

import stup1 from '../assets/imgs/connectors/st-up.svg'
import stdwn1 from '../assets/imgs/connectors/st-dwn1.svg'

export const Loadscreen = ({ loading, setLoading, isSmallScreen }) => {


    const storyPostData = [
        {
            id: "1",
            title: "$KEVS Story begins....",
            twitter: "1750539260548067576"
        },

    ];

    const storyVideoData = [
        {
            id: "2",
            title: "What is the first thing $KEV does with his new found gwealth?",
            vid: vid2,
        },
        {
            id: "3",
            title: "$KEV likes to spread his new wealth with those in need",
            vid: vid3,
        },
        {
            id: "4",
            title: "$KEV won by gambling on shitcoins. He has no reason to stop gambling",
            vid: vid4,
        },
        {
            id: "5",
            title: "$KEV has wanders back into the strip club from the casino...",
            vid: vid5,
        },
        {
            id: "6",
            title: "$KEVS story so far. What will he do next? Join the community and help shape his future",
            vid: vid6,
        },
        {
            id: "7",
            title: "$KEV is a little worn out by the lady and Vegas. He goes for a lil vacay, first time leaving the country in his spud life",
            vid: vid7,
        },
        {
            id: "8",
            title: "Back from his vacay $KEV goes straight back to partying",
            vid: vid8,
        },
        {
            id: "9",
            title: "$KEV decides it's time for a companion... 👀👀👀",
            vid: vid9,
        },
        {
            id: "10",
            title: "$KEV is shitcoining while touching grass... this spud can do both",
            vid: vid10,
        },
        {
            id: "11",
            title: "$KEV watches whats happening on $SOL and has an idea.....",
            vid: vid11,
        },
        {
            id: "12",
            title: "$KEV continues to party deep into the Euro hours",
            vid: vid12,
        },
    ];

    const pulseVariant = {
        pulse: {
            scale: [1, 0.65, 1],
            transition: {
                duration: 0.6,
                ease: "easeInOut",
                repeat: Infinity,
            },
        },
    }


    return (
        <AnimatePresence>
            {loading &&
                <motion.div className='loadingscreen tint' key="modal"
                    initial={{ opacity: 0, scale: 0, x: "-100%", y: "-100%" }}
                    animate={{ opacity: 1, scale: 1, x: 0, y: 0, transition: { type: 'tween', duration: 1 } }}
                    exit={{ opacity: 0, scale: 0, x: "-100%", y: "-100%", transition: { type: 'tween', duration: 1 } }}

                >
                    <div className="story-cnt">
                        <p className="title spray">
                            <span className="ss">$KEV'S</span> STORIES <span className="blue">SO=@</span> FAR#
                        </p>
                        <p className='arc yellow subtitle'>welcome! here you can get a glimpse at kev’s life so far.
                            <br></br>
                            the stories will keep being revealed over time.</p>


                        <div className="item-cnt">
                            {isSmallScreen ?
                                <motion.div className="item-row-mob"

                                >
                                    {
                                        // Map the first entry
                                        storyPostData.slice(0, 1).map(({ id, title, twitter }) => (
                                            <div className="single-item" key={id}>
                                                <div className="item-title hyp">{id}. {title}</div>
                                                <div data-theme="dark" className='twidiv'>
                                                    <Tweet id={twitter} />
                                                </div>
                                            </div>
                                        ))
                                    }


                                    {
                                        storyVideoData.map(({ id, title, vid }) => (
                                            <div className="single-item" key={id}>
                                                <div className="item-title hyp">{id}. {title}</div>
                                                <div className="video-container">
                                                    <video src={vid} alt={`Video titled: ${title}`} className='video-item' controls width={254.4} height={139.2} />
                                                </div>
                                            </div>
                                        ))
                                    }

                                    {
                                        // Map the rest of the entries starting from the second one
                                        storyPostData.slice(1).map(({ id, title, twitter }) => (
                                            <div className="single-item" key={id}>
                                                <div className="item-title hyp">{id}. {title}</div>
                                                <div data-theme="dark" className='twidiv'>
                                                    <Tweet id={twitter} />
                                                </div>
                                            </div>
                                        ))
                                    }



                                </motion.div>
                                :
                                <motion.div className="item-row"
                                    drag="x"  // Enable dragging on the x-axis
                                    dragConstraints={{ left: -2400, right: 2200 }} // Adjusted constraints
                                    initial={{ x: 2200 }}
                                >
                                    {
                                        // Map the first entry
                                        storyPostData.slice(0, 1).map(({ id, title, twitter }) => (
                                            <div className="single-item" key={id}>
                                                <div className="item-title hyp">{id}. {title}</div>
                                                <div data-theme="dark" className='twidiv'>
                                                    <Tweet id={twitter} />
                                                </div>
                                            </div>
                                        ))
                                    }

                                    {
                                        storyVideoData.map(({ id, title, vid }) => (
                                            <div className="single-item" key={id}>
                                                <div className="item-title hyp">{id}. {title}</div>
                                                <div className="video-container">
                                                    <video src={vid} alt={`Video titled: ${title}`} className='video-item' controls width={381.6} height={208.8} />
                                                </div>
                                            </div>
                                        ))
                                    }


                                    {
                                        // Map the rest of the entries starting from the second one
                                        storyPostData.slice(1).map(({ id, title, twitter }) => (
                                            <div className="single-item" key={id}>
                                                <div className="item-title hyp">{id}. {title}</div>
                                                <div data-theme="dark" className='twidiv'>
                                                    <Tweet id={twitter} />
                                                </div>
                                            </div>
                                        ))
                                    }




                                </motion.div>}



                            <img src={stup1} alt="stup1" className="stup1" />
                            <img src={stdwn1} alt="stdwn1" className="stdwn1" />

                        </div>
                        <motion.img

                            src={arrow} onClick={() => setLoading(false)} alt="arrow" className='popup-arrow' variants={pulseVariant}
                            animate="pulse"
                        />

                    </div>


                </motion.div>
            }

        </AnimatePresence>

    )
}
